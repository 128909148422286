import axios from './axios';

const Axios = axios('/corememData');

export function getOrdersPerPage(key, query) {
  const { page, pageSize } = query;
  return Axios.get('/ordersPerPage', {
    params: {
      page,
      pageSize,
      key,
    },
  }).then(({ data }) => data);
}
