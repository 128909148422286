import GunbotStorage, { WALLET_ADDRESS } from 'assets/js/gunbot.storage';
import axios from 'axios';

export default function (baseURL) {
  const Axios = axios.create({
    responseType: 'json',
    baseURL: baseURL ? `/api/v1/${baseURL}` : '/',
  });
  Axios.interceptors.request.use(function (config) {
    config.headers.Authorization = localStorage.getItem('token');
    config.headers.walletAddress = GunbotStorage.get(WALLET_ADDRESS, '');
    return config;
  });
  return Axios;
}
